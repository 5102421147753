import { notificationConstants, warningsConstants } from '../_constants';
import izitoast from 'izitoast';

export const notificationActions = {
    success,
    warning,
    error,
    primary,
    loading,
    clear
};

izitoast.settings({
    timeout: 10000,
    resetOnHover: true,
    displayMode: 2,
    maxWidth: 450,
    layout: 2,
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX'
});

/*
ADICIONAR NO IZITOAST
LINHA 875
if(settings.iconHTML){
    $DOM.icon.innerHTML = settings.iconHTML;
}
*/
function primary(params) {
    let btns = [];
    if (!params){
        params = {};
    }
    if (params.event) {
        btns = [['<button>Abrir</button>', (instance, toast) => {
            params.event();
        }, true]]
    }
    izitoast.show({
        title: params.title ? params.title : "",
        icon: 'notify',
        iconUrl: (params.icon && params.icon),
        message: params.message ? params.message : "",
        color: "dark",
        timeout: params.timeout ? params.timeout : 5000,
        theme: params.theme ? params.theme : 'light',
        position: params.position ? params.position : 'topRight',
        buttons: btns,
        onClosed: function() { if (typeof params.eventOnClose === "function") params.eventOnClose(); }
    });
    return { type: notificationConstants.SUCCESS, ...params};
}

function success(params) {
    clear();
    let btns = [];
    if (!params){
        params = {};
    }
    if (params.event) {
        btns = [['<button>Abrir</button>', (instance, toast) => {
            params.event();
        }, true]]
    }
    
    izitoast.show({
        title: params.title ? params.title : "",
        icon: 'notify',
        iconUrl: (params.icon ? params.icon :'https://api.iconify.design/feather/check-circle.svg'),
        message: params.message ?  params.message : "",
        color: "green",
        timeout: params.timeout ? params.timeout : 5000,
        theme: params.theme ? params.theme : 'light',
        position: params.position? params.position : 'topRight',
        buttons: btns,
        onClosing: function() { if (typeof params.eventOnClose === "function") params.eventOnClose(); }
    });
    return { type: notificationConstants.SUCCESS, ...params};
}

function warning(params) {
    clear();
    let btns = [];
    if (!params){
        params = {};
    }
    if (params.event) {
        btns = [['<button>Abrir</button>', (instance, toast) => {
            params.event();
        }, true]]
    }
    izitoast.show({
        title: params.title ? params.title : "",
        iconUrl: (params.icon ? params.icon : 'https://api.iconify.design/feather/alert-triangle.svg'),
        message: params.message ? params.message : "",
        color: "yellow",
        layout: 1,
        timeout: params.timeout ? params.timeout : 5000,
        theme: params.theme ? params.theme : 'light',
        position: params.position ? params.position : 'topRight',
        buttons: btns,
        onClosing: function() { if (typeof params.eventOnClose === "function") params.eventOnClose(); }
    });
    return { type: notificationConstants.SUCCESS, ...params};
}

function loading(params) {
    let btns = [];
    if (!params){
        params = {};
    }
    if (params.event) {
        btns = [['<button>Abrir</button>', (instance, toast) => {
            params.event();
        }, true]]
    }
    izitoast.show({
        title: params.title ? params.title : warningsConstants.LD_DEFAULT,
        class: "loading-notify",
        iconUrl: (params.icon ? params.icon :  'https://api.iconify.design/feather/settings.svg'),
        iconSpin: true,
        message: params.message ? params.message : "",
        timeout: params.timeout ? params.timeout : false,
        progressBar: true,
        theme: params.theme ? params.theme : 'dark',
        position: params.position ? params.position : 'topRight',
        buttons: btns,
        onClosing: function() { if (typeof params.eventOnClose === "function") params.eventOnClose(); }
    });
    return { type: notificationConstants.SUCCESS, ...params};
}

function error(params) {
    clear();
    let btns = [];
    if (!params){
        params = {};
    }
    if (params.event) {
        btns = [['<button>Abrir</button>', (instance, toast) => {
            params.event();
        }, true]]
    }
    console.log(params);
    izitoast.show({
        title: params.title ? params.title : "",
        icon: 'notify',
        iconUrl: (params.icon ?  'https://api.iconify.design/feather/alert-circle.svg' : params.icon ),
        message: params.message ?  params.message : "",
        color: "red",
        timeout: params.timeout ? params.timeout : 0,
        theme: params.theme ? params.theme : 'light',
        position: params.position ? params.position : 'topRight',
        buttons: btns,
        onClosing: function() { if (typeof params.eventOnClose === "function") params.eventOnClose(); }
    });
    return { type: notificationConstants.SUCCESS, ...params};
}

function clear() {
    var toast = document.querySelectorAll('.iziToast');
    if (toast.length > 0) {
        for(var i = 0;i<toast.length;i++) {
            izitoast.hide({}, toast[i]);
        }
    }
    //izitoast.destroy();
    return { type: notificationConstants.CLEAR };
}
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
//import * as firebase from 'firebase';
//import io from 'socket.io-client';

import App from './App';
import { store } from "./store";
import "bootswatch/dist/flatly/bootstrap.min.css"; 
import './css/main.scss';
import Iconify from "@iconify/iconify";


//Preload icons to notification
Iconify.preloadImages([
    'feather:alert-triangle', 'feather:check-circle', 'feather:settings', 'feather:alert-circle'
]);


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, 
    document.getElementById('root')
);
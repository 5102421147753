import React from 'react';
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';

export default class Button extends React.Component {
    constructor(props) {
        super(props);
        this.eventClick = this.eventClick.bind(this);
    }

    eventClick(evt) {
        evt.preventDefault();
        if (typeof this.props.evtClick === 'object') {
            return this.props.evtClick.fn(this.props.evtClick.params);
        } else if (typeof this.props.evtClick === 'function') {
            return this.props.evtClick(evt);
        }
    }

    render() {
        let {outline, className, color, evtClick, link, icon, type, size, text, externalLink, style, disabled, iconSpin, image, sizeIcon, classIcon} = this.props;
        let classBtn = "btn "+(className ? className: '');
        if (size === "lg") {
            classBtn += " btn-lg";
        } else  if (size === "sm") {
            classBtn += " btn-sm";
        }
        
        if (color === "" || color === undefined) {
            classBtn += (outline ? " btn-outline-primary":" btn-primary");
        } else {
            classBtn += (outline ? " btn-outline-"+color:" btn-"+color);
        }
        if (evtClick) {
            return (<button disabled={disabled} style={style} onClick={this.eventClick} type={type} className={classBtn} >
                {image &&  image}
                {icon &&  <Icon icon={icon} height={sizeIcon}  className={`${iconSpin ? 'spin' : ''} ${classIcon} ${(!text || text === "") ? '' : 'mr-1'}`}/>} 
                {text}
            </button>);
        }
        if (link) {
            return (<Link style={style} to={link} className={classBtn}>
                {image &&  image}
                {icon &&  <Icon icon={icon} height={sizeIcon}  className={`${iconSpin ? 'spin' : ''} ${classIcon} ${(!text || text === "") ? '' : 'mr-1'}`}/>} 
                {text}
            </Link>);
        } else if (externalLink) {
            return (<button style={style} disabled={disabled} onClick={() => { window.open(externalLink); }} className={classBtn}>
                {image &&  image}
                {icon &&  <Icon icon={icon} height={sizeIcon} className={`${iconSpin ? 'spin' : ''} ${classIcon} ${(!text || text === "") ? '' : 'mr-1'}`}/>} 
                {text}
            </button>);
        }
        return (<button disabled={disabled} style={style} type={type} className={classBtn} >
            {image &&  image}
            {icon &&  <Icon icon={icon} height={sizeIcon}  className={`${iconSpin ? 'spin' : ''} ${classIcon} ${(!text || text === "") ? '' : 'mr-1'}`} />} 
            {text}
            </button>);
    }
}

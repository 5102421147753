import React from 'react';
import { Icon } from '@iconify/react';
import '../../css/components.css';

export default class Card extends React.Component {

    render() {
        
        const {title, children, color, icon, className} = this.props;
        let style = {color, borderColor: color}
        return (
            <div className={"card shadow-sm rounded-lg "+(className && className)}>
                <div className="card-body d-flex flex-column">
                    { (title || title === 0) &&
                    <h4 className="title-card" style={style}>{icon && <Icon icon={icon} className="mr-2"/>}<span>{title}</span></h4>}
                    {children}
                </div>
            </div>
        );
    }
}

import React from "react";
import { connect } from "react-redux";


import Icon from '@iconify/react';
import loadingIcon from '@iconify/icons-uil/sync';
import iconDRE from '@iconify/icons-uil/receipt-alt';
import iconReceita from '@iconify/icons-uil/money-withdraw';
import iconCMV from '@iconify/icons-uil/calculator-alt';
import iconEmpresas from '@iconify/icons-uil/building';
import backIcon from '@iconify/icons-uil/arrow-circle-left';
import reportIcon from '@iconify/icons-uil/chart-pie';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';

import Card from './_components/structure/card';
import Dialog from './_components/structure/dialog';
import TextField from './_components/forms/textField';
import Button from './_components/forms/button';
import { mask } from './_components/mask';



class App extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            submitted: false,
            showReport: false,
            activeIndex: 0,

            receitaTrimestralCombustivel:  0,
            receitaTrimestralLubrificantes: 0,
            FaturamentoPJAlugueis: 0,
            FaturamentoPJADM: 0,

            //Situacão 1
            EIS1:  0,
            ComprasS1:  0,
            EFS1: 0,
            ImpostosS1: 0,
            DespesasADMS1: 0,
            ServicosPJApoioS1: 0,
            HoldingS1: 0,
            DespesasTributariasS1: 0,
            ReceitasFinanceirasS1: 0,
            DepesasFinanceirasS1:0,
            OutrasReceitasS1: 0,
            OutrasDespesasS1: 0,


            //Situacão 2
            EIS2: 0,
            ComprasS2: 0,
            EFS2: 0,

            finalConsiderations: ""
        };
    }

    onChange = evt => {
        const { dispatch } = this.props;
        if (this.state.submitted) {
            dispatch(notificationActions.clear());
            this.setState({ submitted: false });
        }
        //let x = parseFloat(evt.target.value.replace('R$','').replace(/\./g, "").replace(',','.').trim());
        this.setState({ [evt.target.name]: evt.target.value });
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const {
            cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
            payload, percent, value,
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        const fill = this.state.activeIndex === 0 ? "#333" : "#999";

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${mask.money(value)}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { submitted } = this.state;
        if (!submitted) {
            this.setState({submitted: true});
        } else {
            return;
        }

        this.setState({submitted: true});
        setTimeout(() => {
            
            this.setState({
                ComprasS1: typeof this.state.ComprasS1 === 'number' ? this.state.ComprasS1 : (this.state.ComprasS1 === 0 ? 0 : parseFloat(this.state.ComprasS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                ComprasS2: typeof this.state.ComprasS2 === 'number' ? this.state.ComprasS2 : (this.state.ComprasS2 === 0 ? 0 : parseFloat(this.state.ComprasS2.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                DepesasFinanceirasS1: typeof this.state.DepesasFinanceirasS1 === 'number' ? this.state.DepesasFinanceirasS1 : (this.state.DepesasFinanceirasS1 === 0 ? 0 : parseFloat(this.state.DepesasFinanceirasS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                DespesasADMS1: typeof this.state.DespesasADMS1 === 'number' ? this.state.DespesasADMS1 : (this.state.DespesasADMS1 === 0 ? 0 : parseFloat(this.state.DespesasADMS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                DespesasTributariasS1: typeof this.state.DespesasTributariasS1 === 'number' ? this.state.DespesasTributariasS1 : (this.state.DespesasTributariasS1 === 0 ? 0 : parseFloat(this.state.DespesasTributariasS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                EFS1: typeof this.state.EFS1 === 'number' ? this.state.EFS1 : (this.state.EFS1 === 0 ? 0 : parseFloat(this.state.EFS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                EFS2: typeof this.state.EFS2 === 'number' ? this.state.EFS2 : (this.state.EFS2 === 0 ? 0 : parseFloat(this.state.EFS2.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                EIS1: typeof this.state.EIS1 === 'number' ? this.state.EIS1 : (this.state.EIS1 === 0 ? 0 : parseFloat(this.state.EIS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                EIS2: typeof this.state.EIS2 === 'number' ? this.state.EIS2 : (this.state.EIS2 === 0 ? 0 : parseFloat(this.state.EIS2.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                FaturamentoPJADM: typeof this.state.FaturamentoPJADM === 'number' ? this.state.FaturamentoPJADM : (this.state.FaturamentoPJADM === 0 ? 0 : parseFloat(this.state.FaturamentoPJADM.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                FaturamentoPJAlugueis: typeof this.state.FaturamentoPJAlugueis === 'number' ? this.state.FaturamentoPJAlugueis : (this.state.FaturamentoPJAlugueis === 0 ? 0 : parseFloat(this.state.FaturamentoPJAlugueis.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                HoldingS1: typeof this.state.HoldingS1 === 'number' ? this.state.HoldingS1 : (this.state.HoldingS1 === 0 ? 0 : parseFloat(this.state.HoldingS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                ImpostosS1: typeof this.state.ImpostosS1 === 'number' ? this.state.ImpostosS1 : (this.state.ImpostosS1 === 0 ? 0 : parseFloat(this.state.ImpostosS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                OutrasReceitasS1: typeof this.state.OutrasReceitasS1 === 'number' ? this.state.OutrasReceitasS1 : (this.state.OutrasReceitasS1 === 0 ? 0 : parseFloat(this.state.OutrasReceitasS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                OutrasDespesasS1: typeof this.state.OutrasDespesasS1 === 'number' ? this.state.OutrasDespesasS1 : (this.state.OutrasDespesasS1 === 0 ? 0 : parseFloat(this.state.OutrasDespesasS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                ReceitasFinanceirasS1: typeof this.state.ReceitasFinanceirasS1 === 'number' ? this.state.ReceitasFinanceirasS1 : (this.state.ReceitasFinanceirasS1 === 0 ? 0 : parseFloat(this.state.ReceitasFinanceirasS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                ServicosPJApoioS1: typeof this.state.ServicosPJApoioS1 === 'number' ? this.state.ServicosPJApoioS1 : (this.state.ServicosPJApoioS1 === 0 ? 0 : parseFloat(this.state.ServicosPJApoioS1.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                receitaTrimestralCombustivel: typeof this.state.receitaTrimestralCombustivel === 'number' ? this.state.receitaTrimestralCombustivel : (this.state.receitaTrimestralCombustivel === 0 ? 0 : parseFloat(this.state.receitaTrimestralCombustivel.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                receitaTrimestralLubrificantes: typeof this.state.receitaTrimestralLubrificantes === 'number' ? this.state.receitaTrimestralLubrificantes : (this.state.receitaTrimestralLubrificantes === 0 ? 0 : parseFloat(this.state.receitaTrimestralLubrificantes.replace('R$','').replace(/\./g, "").replace(',','.').trim())),
                showReport: true,
                submitted: false
            });
        }, 1000);
    }

    render() {
        const {
            submitted, showReport,
            receitaTrimestralCombustivel, receitaTrimestralLubrificantes,
            FaturamentoPJAlugueis, FaturamentoPJADM,

            //Situacão 1
            EIS1, ComprasS1, EFS1,
            ImpostosS1, DespesasADMS1, ServicosPJApoioS1, HoldingS1, DespesasTributariasS1, ReceitasFinanceirasS1, DepesasFinanceirasS1, OutrasReceitasS1, OutrasDespesasS1,


            //Situacão 1
            EIS2, ComprasS2, EFS2,

            finalConsiderations
        } = this.state;
        const { alert } = this.props;
        let lucroAntesIRS1 = 0,
            lucroAntesIRS2 = 0,
            CSLL_S1 = 0,
            IRPJ_S1 = 0,
            CSLL_S2 = 0,
            IRPJ_S2 = 0,
            IRPJ_Lub = 0,
            CSLL_Lub = 0,
            IRPJ_Comb = 0,
            CSLL_Comb = 0,
            DepesasOperacionais = 0;

        if (showReport) {
            DepesasOperacionais = parseFloat(DespesasADMS1 + FaturamentoPJADM + OutrasDespesasS1 + FaturamentoPJAlugueis + DespesasTributariasS1 + DepesasFinanceirasS1 + HoldingS1);
            lucroAntesIRS1 = parseFloat((
                ( ((receitaTrimestralLubrificantes + receitaTrimestralCombustivel) - ImpostosS1) - (EIS1 + ComprasS1 - EFS1) ) - //Lucro Bruto
                DepesasOperacionais + (OutrasReceitasS1 + ReceitasFinanceirasS1)
            ).toFixed(2));

            lucroAntesIRS2 = parseFloat((((
                (receitaTrimestralLubrificantes + receitaTrimestralCombustivel) - ImpostosS1) - (EIS2 + ComprasS2 - EFS2)) - //Lucro Bruto
                DepesasOperacionais + (OutrasReceitasS1 + ReceitasFinanceirasS1)
            ).toFixed(2));

            CSLL_S1 = parseFloat((lucroAntesIRS1 * 0.09).toFixed(2));
            IRPJ_S1 = parseFloat((lucroAntesIRS1 * 0.15).toFixed(2));

            CSLL_S2 = parseFloat((lucroAntesIRS2 * 0.09).toFixed(2));
            IRPJ_S2 = parseFloat((lucroAntesIRS2 * 0.15).toFixed(2));

            IRPJ_Lub = parseFloat(((receitaTrimestralLubrificantes * 0.08) * 0.15).toFixed(2));
            CSLL_Lub = parseFloat(((receitaTrimestralLubrificantes * 0.12) * 0.09).toFixed(2));

            IRPJ_Comb = parseFloat(((receitaTrimestralCombustivel * 0.016) * 0.15).toFixed(2));
            CSLL_Comb = parseFloat(((receitaTrimestralCombustivel * 0.12) * 0.09).toFixed(2));
        }

        const dataChart1 = [
            { 
                name: 'Lucro Presumido', 
                value: (CSLL_Lub + CSLL_Comb + IRPJ_Lub + IRPJ_Comb),
            },
            { name: 'Lucro Real', value: lucroAntesIRS1 <= 0 ? 0 : (IRPJ_S1 + CSLL_S1) },
        ];
        const ecomony1 = ((lucroAntesIRS1 <= 0 ? 0 : (IRPJ_S1 + CSLL_S1)) - (CSLL_Lub + CSLL_Comb + IRPJ_Lub + IRPJ_Comb));
        const percentEconomy1 = ((ecomony1*100) / (CSLL_Lub + CSLL_Comb + IRPJ_Lub + IRPJ_Comb));

        const dataChart2 = [
            { name: 'Lucro Presumido', value: (CSLL_Lub + CSLL_Comb + IRPJ_Lub + IRPJ_Comb) },
            { name: 'Lucro Real', value: lucroAntesIRS2 <= 0 ? 0 : (IRPJ_S2 + CSLL_S2) },
        ];
        const ecomony2 = ((lucroAntesIRS2 <= 0 ? 0 : (IRPJ_S2 + CSLL_S2)) - (CSLL_Lub + CSLL_Comb + IRPJ_Lub + IRPJ_Comb));
        const percentEconomy2 = ((ecomony2*100) / (CSLL_Lub + CSLL_Comb + IRPJ_Lub + IRPJ_Comb));
        
        const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


        return (
            <div className="bg-transparent">
                { (alert.params) && <Dialog />}
                <img src="bomba.png" width="150" className={"position-fixed noprint"} style={{top: 80, zIndex: 0, right: 0}}/>
                <img src="caminhao.png" width="300" className={"position-fixed noprint"} style={{bottom: 30, zIndex: 0, left: 0}}/>
                <div className="content content-login d-flex flex-column justify-content-center align-items-center">
                    {
                        !showReport &&
                        <div className="d-flex justify-content-center">
                            <section className="col-11 mt-2">
                                <div className="d-flex justify-content-center align-items-center mb-3">
                                    <img alt="Logo" src={"logo.png"} height="150" />
                                </div>
                                <form  onSubmit={this.onSubmit}>
                                <div className="card p-0 shadow mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 mb-2">
                                                    <h3 className="h4 font-weight-bold text-info m-0">
                                                        <Icon icon={iconReceita} size={24} className={"mr-1"}/>
                                                        Receita
                                                    </h3>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={receitaTrimestralCombustivel} mask={"money"} name="receitaTrimestralCombustivel" required={true} onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Receita Trimestral de Combustível"} />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={receitaTrimestralLubrificantes} mask={"money"} name="receitaTrimestralLubrificantes" required={true} onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Receita Trimestral de Lubrificantes"} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-3 mb-2">
                                                    <h3 className="h4 font-weight-bold text-info m-0">
                                                        <Icon icon={iconDRE} size={24} className={"mr-1"}/>
                                                        Deduções
                                                    </h3>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={ImpostosS1} mask={"money"} required={true} name="ImpostosS1" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Deduções da Receita(PIS/COFINS)"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card p-0 shadow mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 mb-2">
                                                    <h3 className="h4 font-weight-bold text-success m-0">
                                                        <Icon icon={iconCMV} size={24} className={"mr-1"}/>
                                                        Situação 1 - CMV
                                                    </h3>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={EIS1} mask={"money"} required={true} name="EIS1" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"EI - Situação 1"} />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={ComprasS1} mask={"money"} required={true} name="ComprasS1" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Compras - Situação 1"} />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={EFS1} mask={"money"} required={true} name="EFS1" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"EF - Situação 1"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card p-0 shadow mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 mb-2">
                                                    <h3 className="h4 font-weight-bold text-danger m-0">
                                                        <Icon icon={iconCMV} size={24} className={"mr-1"}/>
                                                        Situação 2 - CMV
                                                    </h3>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={EIS2} mask={"money"} required={true} name="EIS2" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"EI - Situação 2"} />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={ComprasS2} mask={"money"} required={true} name="ComprasS2" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Compras - Situação 2"} />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={EFS2} mask={"money"} required={true} name="EFS2" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"EF - Situação 2"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                   
                                    <div className="card p-0 shadow mb-4">
                                        <div className="card-body">
                                            
                                            <div className="row">
                                                <div className="col-12 mt-3 mb-2">
                                                    <h3 className="h4 font-weight-bold text-info m-0">
                                                        <Icon icon={iconDRE} size={24} className={"mr-1"}/>
                                                        Despesas/Receitas
                                                    </h3>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={DespesasADMS1} mask={"money"} required={true} name="DespesasADMS1" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Despesas Administrativas"} />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={DespesasTributariasS1} mask={"money"} required={true} name="DespesasTributariasS1" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Despesas Tributarias"} />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={ReceitasFinanceirasS1} mask={"money"} required={true} name="ReceitasFinanceirasS1" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Receitas Financeiras"} />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={DepesasFinanceirasS1} mask={"money"} required={true} name="DepesasFinanceirasS1" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Despesas Financeiras"} />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={OutrasReceitasS1} mask={"money"} required={true} name="OutrasReceitasS1" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Outras Receitas"} />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
                                                    <TextField value={OutrasDespesasS1} mask={"money"} required={true} name="OutrasDespesasS1" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Outras Despesas"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card p-0 shadow mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 mb-2">
                                                    <h3 className="h4 font-weight-bold text-warning m-0">
                                                        <Icon icon={iconEmpresas} size={24} className={"mr-1"}/>
                                                        Empresas de Apoio
                                                    </h3>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                                    <TextField value={FaturamentoPJADM} mask={"money"} required={true} name="FaturamentoPJADM" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Empresa de Apoio ADM - Simples"} />
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                                    <TextField value={FaturamentoPJAlugueis} mask={"money"} required={true} name="FaturamentoPJAlugueis" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Empresa de administração de bens - Presumido"} />
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                                                    <TextField value={HoldingS1} mask={"money"} required={true} name="HoldingS1" onChange={e => this.onChange(e)} type="text" placeholder={"R$ 0,00"} label={"Aluguéis de Postos(Holding) - Presumido"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card p-0 shadow mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <TextField value={finalConsiderations}  name="finalConsiderations" onChange={e => this.onChange(e)} type="textarea" placeholder={""} label={"Considerações Finais"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <hr />
                                    {
                                        !submitted ?
                                            <Button disabled={submitted} type="submit" icon={reportIcon} outline={false} text={"Gerar Relatório"} color="success" className="btn-block btn-lg mt-3" /> :
                                            <Button disabled type="submit" iconSpin={true} icon={loadingIcon} text={"Gerando..."} color="success" className="btn-block btn-lg mt-3" />
                                    }
                                </form>
                            </section>
                        </div>
                    }
                    {
                        showReport &&
                        <div className="col-sm-12 col-md-11 col-lg-10 col-xl-10">
                            <div className="d-flex justify-content-center align-items-center mb-3">
                                <img alt="Logo" src={"logo.png"} height="150" />
                            </div>
                            <div className="d-flex flex-row flex-wrap justify-content-center">
                                <Card title="Lucro Presumido" color={"#00489F"} className="m-3 flex-fill">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Mercadorias</th>
                                                <th>Receita Trimestral</th>
                                                <th>Alíquota - IRPJ</th>
                                                <th>Alíquota - CSLL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Combustível</td>
                                                <td>{mask.money(receitaTrimestralCombustivel)}</td>
                                                <td>{mask.money(IRPJ_Comb)}</td>
                                                <td>{mask.money(CSLL_Comb)}</td>
                                            </tr>
                                            <tr>
                                                <td>Lubrificantes</td>
                                                <td>{mask.money(receitaTrimestralLubrificantes)}</td>
                                                <td>{mask.money(IRPJ_Lub)}</td>
                                                <td>{mask.money(CSLL_Lub)}</td>
                                            </tr>
                                        </tbody>

                                        <tfoot className="font-weight-bold">
                                            <td>Total Imposto</td>
                                            <td></td>
                                            <td></td>
                                            <td>{mask.money((CSLL_Lub + CSLL_Comb) + (IRPJ_Lub + IRPJ_Comb))}</td>
                                        </tfoot>
                                        <tfoot className="font-weight-bold">
                                            <td>Total</td>
                                            <td>{mask.money(receitaTrimestralLubrificantes + receitaTrimestralCombustivel)}</td>
                                            <td>{mask.money(IRPJ_Lub + IRPJ_Comb)}</td>
                                            <td>{mask.money(CSLL_Lub + CSLL_Comb)}</td>
                                        </tfoot>

                                    </table>
                                </Card>

                                <Card title="Lucro Real" color={"#20bf3d"} className="m-3 flex-fill">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Atividade</th>
                                                <th>Lucro Liq.</th>
                                                <th>Valor do IRPJ</th>
                                                <th>Valor do CSLL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Receita</td>
                                                <td className={lucroAntesIRS1 < 0 ? 'text-danger' : ''}>
                                                    {   
                                                        lucroAntesIRS1 < 0 && '-'+mask.money(lucroAntesIRS1) 
                                                    }
                                                    {   
                                                        lucroAntesIRS1 >= 0 && mask.money(lucroAntesIRS1) 
                                                    }
                                                </td>
                                                <td>{lucroAntesIRS1 <= 0 ? 'R$0,00' : mask.money(IRPJ_S1)}</td>
                                                <td>{lucroAntesIRS1 <= 0 ? 'R$0,00' : mask.money(CSLL_S1)}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot className="font-weight-bold">
                                            <td>Total de Imposto</td>
                                            <td></td>
                                            <td></td>
                                            <td>{lucroAntesIRS1 <= 0 ? 'R$0,00' : mask.money(IRPJ_S1 + CSLL_S1)}</td>
                                        </tfoot>
                                    </table>
                                </Card>
                            </div>

                            <div className="d-flex flex-row flex-wrap justify-content-center">
                                <Card title="Custo da Mercadoria - CMV" color={"#00B4CE"} className="m-3">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Situações</th>
                                                <th>EI</th>
                                                <th>Compras</th>
                                                <th>EF</th>
                                                <th>Total CMV</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Situação 1</td>
                                                <td>{mask.money(EIS1)}</td>
                                                <td>{mask.money(ComprasS1)}</td>
                                                <td>{mask.money(EFS1)}</td>
                                                <td className="font-weight-bold">{mask.money(EIS1 + ComprasS1 - EFS1)}</td>
                                            </tr>
                                            <tr>
                                                <td>Situação 2</td>
                                                <td>{mask.money(EIS2)}</td>
                                                <td>{mask.money(ComprasS2)}</td>
                                                <td>{mask.money(EFS2)}</td>
                                                <td className="font-weight-bold">{mask.money(EIS2 + ComprasS2 - EFS2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card>                               
                            </div>
                            <div className="d-flex flex-row flex-wrap justify-content-center">
                                <div className="m-3 justify-content-center align-items-center d-flex">
                                    <ResponsiveContainer width={600} height={500}>
                                        <PieChart>
                                            <Pie 
                                            data={dataChart1} 
                                            dataKey="value" 
                                            nameKey="name"
                                            label={({
                                                cx, cy, midAngle, outerRadius, innerRadius,
                                                fill, percent, name, value
                                            }) => {
                                                const RADIAN = Math.PI / 180;
                                                const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                                                const sin = Math.sin(-RADIAN * midAngle);
                                                const cos = Math.cos(-RADIAN * midAngle);
                                                const sx = cx + (outerRadius + 10) * cos;
                                                const sy = cy + (outerRadius + 10) * sin;
                                                const mx = cx + (outerRadius + 30) * cos;
                                                const my = cy + (outerRadius + 30) * sin;
                                                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                                                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                                                const textAnchor = cos >= 0 ? 'start' : 'end';
                                            
                                                return (
                                                <g style={{backgroundColor: "#f9f9f9"}}>
                                                    <path d={`M${sx},${sy}L${mx},${my}L${ex},${my}`} stroke={fill} fill="none" />
                                                    <circle cx={ex} cy={my} r={2} fill={fill} stroke="none" />
                                                    <text x={ex + (cos >= 0 ? 1 : -1) * 9} y={my} textAnchor={textAnchor} fill="#333">{`${name}`}</text>
                                                    <text x={ex + (cos >= 0 ? 1 : -1) * 9} y={my} dy={18} textAnchor={textAnchor} fill="#999">
                                                    {mask.money(value)}
                                                    </text>
                                                    <text x={x+10} y={y} fill="#333" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                                                    { `${(percent * 100).toFixed(2)}%` }
                                                    </text>
                                                </g>
                                            )}}
                                            outerRadius={100}
                                            >
                                            {
                                                dataChart1.map((_, index) => <Cell key={index} fill={COLORS[index]} />)
                                            }
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                    <Card title={"Economia"}  color={"#20bf3d"}>
                                        <b className="d-block">{mask.money(ecomony1)}</b>
                                        <span>{percentEconomy1.toFixed(2)}%</span>
                                    </Card>
                                </div>
                                <div className="m-3 justify-content-center align-items-center d-flex">
                                    <ResponsiveContainer width={600} height={500}>
                                        <PieChart>
                                            <Pie 
                                                data={dataChart2} 
                                                dataKey="value" 
                                                nameKey="name"
                                                label={({
                                                    cx, cy, midAngle, outerRadius, innerRadius,
                                                    fill, percent, name, value
                                                }) => {
                                                    const RADIAN = Math.PI / 180;
                                                    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                                                    const sin = Math.sin(-RADIAN * midAngle);
                                                    const cos = Math.cos(-RADIAN * midAngle);
                                                    const sx = cx + (outerRadius + 10) * cos;
                                                    const sy = cy + (outerRadius + 10) * sin;
                                                    const mx = cx + (outerRadius + 30) * cos;
                                                    const my = cy + (outerRadius + 30) * sin;
                                                    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                                                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                                    const y = cy + radius * Math.sin(-midAngle * RADIAN);
                                                    const textAnchor = cos >= 0 ? 'start' : 'end';
                                                
                                                    return (
                                                    <g>
                                                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${my}`} stroke={fill} fill="none" />
                                                        <circle cx={ex} cy={my} r={2} fill={fill} stroke="none" />
                                                        <text x={ex + (cos >= 0 ? 1 : -1) * 9} y={my} textAnchor={textAnchor} fill="#333">{`${name}`}</text>
                                                        <text x={ex + (cos >= 0 ? 1 : -1) * 9} y={my} dy={18} textAnchor={textAnchor} fill="#999">
                                                        {mask.money(value)}
                                                        </text>
                                                        <text x={x+10} y={y} fill="#333" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                                                        { `${(percent * 100).toFixed(2)}%` }
                                                        </text>
                                                    </g>
                                                )}}
                                                outerRadius={100}
                                                >
                                                {
                                                    dataChart1.map((_, index) => <Cell key={index} fill={COLORS[index]} />)
                                                }
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                    <Card title={"Economia"}  color={"#20bf3d"}>
                                        <b className="d-block">{mask.money(ecomony2)}</b>
                                        <span>{percentEconomy2.toFixed(2)}%</span>
                                    </Card>
                                </div>
                            </div>
                            
                            <div className="d-flex flex-row flex-wrap justify-content-center">
                                <Card title="DRE" color={"#000"} className="m-3 flex-fill">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Descrição</th>
                                                <th>Situação 1</th>
                                                <th>Situação 2</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Receita Bruta</td>
                                                <td>{mask.money(receitaTrimestralLubrificantes + receitaTrimestralCombustivel)}</td>
                                                <td>{mask.money(receitaTrimestralLubrificantes + receitaTrimestralCombustivel)}</td>
                                            </tr>
                                            <tr>
                                                <td>Dedução de Impostos(PIS/COFINS) (-)</td>
                                                <td>{mask.money(ImpostosS1)}</td>
                                                <td>{mask.money(ImpostosS1)}</td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Receita Líquida</td>
                                                <td>{mask.money((receitaTrimestralLubrificantes + receitaTrimestralCombustivel) - ImpostosS1)}</td>
                                                <td>{mask.money((receitaTrimestralLubrificantes + receitaTrimestralCombustivel) - ImpostosS1)}</td>
                                            </tr>
                                            <tr>
                                                <td>CMV (-)</td>
                                                <td>{mask.money(EIS1 + ComprasS1 - EFS1)}</td>
                                                <td>{mask.money(EIS2 + ComprasS2 - EFS2)}</td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Lucro Bruto</td>
                                                <td>{mask.money(((receitaTrimestralLubrificantes + receitaTrimestralCombustivel) - ImpostosS1) - (EIS1 + ComprasS1 - EFS1))}</td>
                                                <td>{mask.money(((receitaTrimestralLubrificantes + receitaTrimestralCombustivel) - ImpostosS1) - (EIS2 + ComprasS2 - EFS2))}</td>
                                            </tr>
                                            <tr>
                                                <td>Despesas Operacionais (-)</td>
                                                <td>{mask.money(DepesasOperacionais)}</td>
                                                <td>{mask.money(DepesasOperacionais)}</td>
                                            </tr>
                                            <tr>
                                                <td>Despesas Administrativas (-)</td>
                                                <td>{mask.money((DespesasADMS1))}</td>
                                                <td>{mask.money((DespesasADMS1))}</td>
                                            </tr>
                                            <tr className="table-primary">
                                                <td>Serviços Prestados PJ Apoio ADM  (-)</td>
                                                <td>{mask.money(FaturamentoPJADM)}</td>
                                                <td>{mask.money(FaturamentoPJADM)}</td>
                                            </tr>
                                            <tr  className="table-info">
                                                <td>Serviços Prestados PJ de Bens  (-)</td>
                                                <td>{mask.money(FaturamentoPJAlugueis)}</td>
                                                <td>{mask.money(FaturamentoPJAlugueis)}</td>
                                            </tr>
                                            <tr className="table-success">
                                                <td>Serviços Aluguéis (Holding)  (-)</td>
                                                <td>{mask.money(HoldingS1)}</td>
                                                <td>{mask.money(HoldingS1)}</td>
                                            </tr>
                                            <tr>
                                                <td>Despesas Tributárias  (-)</td>
                                                <td>{mask.money(DespesasTributariasS1)}</td>
                                                <td>{mask.money(DespesasTributariasS1)}</td>
                                            </tr>
                                            <tr>
                                                <td>Receitas Financeiras  (+)</td>
                                                <td>{mask.money(ReceitasFinanceirasS1)}</td>
                                                <td>{mask.money(ReceitasFinanceirasS1)}</td>
                                            </tr>
                                            <tr>
                                                <td>Despesas Financeiras  (-)</td>
                                                <td>{mask.money(DepesasFinanceirasS1)}</td>
                                                <td>{mask.money(DepesasFinanceirasS1)}</td>
                                            </tr>
                                            <tr>
                                                <td>Outras Receitas  (+)</td>
                                                <td>{mask.money(OutrasReceitasS1)}</td>
                                                <td>{mask.money(OutrasReceitasS1)}</td>
                                            </tr>
                                            <tr>
                                                <td>Outras Despesas  (-)</td>
                                                <td>{mask.money(OutrasDespesasS1)}</td>
                                                <td>{mask.money(OutrasDespesasS1)}</td>
                                            </tr>
                                            <tr className="font-weight-bold bg-secondary">
                                                <td>Lucro antes do IR</td>
                                                <td className={lucroAntesIRS1 < 0 ? 'text-danger' : ''}>
                                                    { (lucroAntesIRS1 < 0 ? '-' : '')+mask.money(lucroAntesIRS1) }
                                                </td>
                                                <td className={lucroAntesIRS2 < 0 ? 'text-danger' : ''}>
                                                    { (lucroAntesIRS2 < 0 ? '-' : '')+mask.money(lucroAntesIRS2) }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>IRPJ(15%)  (-)</td>
                                                <td>
                                                    {lucroAntesIRS1 <= 0 ? 'R$0,00' : mask.money(IRPJ_S1)}
                                                </td>
                                                <td>
                                                    {lucroAntesIRS2 <= 0 ? 'R$0,00' : mask.money(IRPJ_S2)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>CSLL(9%)  (-)</td>
                                                <td>
                                                    {lucroAntesIRS1 <= 0 ? 'R$0,00' : mask.money(CSLL_S1)}
                                                </td>
                                                <td>
                                                    {lucroAntesIRS2 <= 0 ? 'R$0,00' : mask.money(CSLL_S2)}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot className="font-weight-bold">
                                            <td>Lucro Líquido</td>
                                            <td className={lucroAntesIRS1 < 0 ? 'text-danger' : ''}>
                                                {   
                                                    lucroAntesIRS1 < 0 && '-'+mask.money(lucroAntesIRS1) 
                                                }
                                                {   
                                                    lucroAntesIRS1 == 0 && mask.money(lucroAntesIRS1) 
                                                }
                                                {   
                                                    lucroAntesIRS1 > 0 && mask.money(lucroAntesIRS1 - (IRPJ_S1 + CSLL_S1))
                                                }
                                            </td>
                                            <td className={lucroAntesIRS2 < 0 ? 'text-danger' : ''}>
                                                {   
                                                    lucroAntesIRS2 < 0 && '-'+mask.money(lucroAntesIRS2) 
                                                }
                                                {   
                                                    lucroAntesIRS2 == 0 && mask.money(lucroAntesIRS2) 
                                                }
                                                {   
                                                    lucroAntesIRS2 > 0 && mask.money(lucroAntesIRS2 - (IRPJ_S2 + CSLL_S2))
                                                }
                                            </td>
                                        </tfoot>
                                    </table>
                                </Card>
                            </div>

                            <div className="d-flex flex-row flex-wrap justify-content-center">
                                <Card title="Empresa de Serviço de Apoio Administrativo - SIMPLES" color={"#08376F"} className="m-3 flex-fill">
                                    <table className="table table-hover table-striped">
                                        <tbody>
                                            <tr>
                                                <td>Faturamento</td>
                                                <td>{mask.money(FaturamentoPJADM)}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot className="font-weight-bold">
                                            <td>Valor do Simples</td>
                                            <td>{mask.money(FaturamentoPJADM * 0.06)}</td>
                                        </tfoot>
                                    </table>
                                </Card>

                                <Card title="Empresa de Administração de bens e alugueis de imoveis proprios - Presumido" color={"#08376F"} className="m-3 flex-fill">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Atividade</th>
                                                <th>Receita Trimestral</th>
                                                <th>Alíquota do IRPJ</th>
                                                <th>Alíuota do CSLL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Serviços</td>
                                                <td>{mask.money(FaturamentoPJAlugueis)}</td>
                                                <td>{mask.money(FaturamentoPJAlugueis * 0.32 * 0.15)}</td>
                                                <td>{mask.money(FaturamentoPJAlugueis * 0.12 * 0.09)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card>

                                <Card title="Serviços de aluguéis - Presumido" color={"#08376F"} className="m-3 flex-fill">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Atividade</th>
                                                <th>Receita Trimestral</th>
                                                <th>Alíquota do IRPJ</th>
                                                <th>Alíuota do CSLL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Serviços</td>
                                                <td>{mask.money(HoldingS1)}</td>
                                                <td>{mask.money(HoldingS1 * 0.32 * 0.15)}</td>
                                                <td>{mask.money(HoldingS1 * 0.12 * 0.09)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card>
                            </div>

                            <div className="d-flex flex-row flex-wrap justify-content-center">
                                <Card title="Diferença de Situações" color={"#00489F"} className="m-3 flex-fill">
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Situação</th>
                                                <th>Valor do Imposto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Sem as empresas de apoio administrativo e Holding</td>
                                                <td>{mask.money((IRPJ_S1 + CSLL_S1))}</td>
                                            </tr>
                                            <tr>
                                                <td>Com a empresa de apoio administrativo</td>
                                                <td>{mask.money((IRPJ_S1 + CSLL_S1) - ((FaturamentoPJADM * 0.15) + (FaturamentoPJADM * 0.09)))}</td>
                                            </tr>
                                            <tr>
                                                <td>Com as empresas de apoio administrativo e Holding</td>
                                                <td>{mask.money(((IRPJ_S1 + CSLL_S1) - ((FaturamentoPJADM * 0.15) + (FaturamentoPJADM * 0.09))) - ((FaturamentoPJAlugueis * 0.15) + (FaturamentoPJAlugueis * 0.09)))}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Card>
                            </div>

                            <div className="d-flex flex-row flex-wrap justify-content-center">           
                                <Card title="Considerações Finais" color={"#00489F"} className="m-3 flex-fill">
                                    <p className="text-center h4 my-3 p-0">{finalConsiderations}</p>
                                </Card>
                            </div>


                            <Button icon={backIcon} evtClick={() => this.setState({showReport: false, submitted: false})} color="danger" size={"lg"} className="position-fixed noprint" style={{bottom:"5%", right:"5%"}} text={"Voltar"}/>
                        </div>
                    }
                    <footer className="container text-center mt-5">
                        <small className="text-dark">© <a href="https://emaacont.com.br" className="text-dark font-weight-bold">Emaacont</a></small>
                    </footer>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert
    };
};

export default connect(mapStateToProps)(App);
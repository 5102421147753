import React from 'react';
import { connect } from "react-redux";
import { alertActions } from '../../_actions';
import  { alertConstants }  from '../../_constants';

import { Icon, InlineIcon} from '@iconify/react';
import loadingIcon from '@iconify/icons-feather/settings';
import x from '@iconify/icons-feather/x';

class Dialog extends React.Component {
    constructor(props){
        super(props);
        this.closeDialog = this.closeDialog.bind(this);
        this.evtPrimary = this.evtPrimary.bind(this);
        this.evtSecondary = this.evtSecondary.bind(this);
        this.evtKeyboard = this.evtKeyboard.bind(this);
    }

    closeDialog(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(alertActions.clear());
        const { eventOnClose } = this.props.alert.params;
        if (typeof eventOnClose === 'function') {
            eventOnClose(e);
        }
    }

    evtPrimary(evt){
        evt.preventDefault();
        const { eventPrimary } = this.props.alert.params;
        if (typeof eventPrimary === 'function') {
            eventPrimary(evt, ()=>{this.closeDialog(evt);});
        } else {
            this.closeDialog(evt);
        }
    }
    
    evtSecondary(evt){
        evt.preventDefault();
        const { eventSecondary } = this.props.alert.params;
        if (typeof eventSecondary === 'function') {
            eventSecondary(evt);
        }
        this.closeDialog(evt);
    }

    evtKeyboard(evt){
        evt.preventDefault();
        //ESC
        if (evt.keyCode === 27) {
            this.closeDialog(evt);
        }
        //ENTER
        if (evt.keyCode === 13) {
            this.evtPrimary(evt);
        }
    }

    componentDidMount(){
        const { type } = this.props.alert;
        if (type !== alertConstants.DIALOG) {
            document.addEventListener("keydown", this.evtKeyboard);
        }
        document.querySelector("body").classList.add("no-scroll");
    }
    
    update = () => {
        this.forceUpdate();
    }
    
    componentWillUnmount() {
        const { type } = this.props.alert;
        if (type !== alertConstants.DIALOG) {
            document.removeEventListener("keydown", this.evtKeyboard);
        }
        document.querySelector("body").classList.remove("no-scroll");
    }

    render() {
        const { size, allScreen, content, noButtons, btnSecondaryOutline, btnPrimaryOutline, title, icon, message, iconTitle, textBtnPrimary, colorBtnPrimary, textBtnSecondary, colorBtnSecondary } = this.props.alert.params;
        const { alert, language } = this.props;
        const { type } = alert;
        let classText;
        switch(type){
            case alertConstants.SUCCESS:
                //classType = "alert-success";
                classText = "text-center font-weight-bold text-success";
                break;
            case alertConstants.WARNING:
                //classType = "alert-warning";
                classText = "text-center font-weight-bold text-warning";
                break;
            case alertConstants.ERROR:
                //classType = "alert-danger";
                classText = "text-center font-weight-bold text-danger";
                break;
            case alertConstants.INFO:
                //classType = "alert-info";
                classText = "text-center font-weight-bold font-weight-bold text-info";
                break;
            case alertConstants.PRIMARY:
                //classType = "alert-info";
                classText = "text-center font-weight-bold font-weight-bold text-primary";
                break;
            case alertConstants.CONFIRM:
                //classType = "";
                classText = "text-center font-weight-bold text-primary";
                break;
            case alertConstants.LOADING:
                //classType = "";
                classText = "text-center font-weight-bold text-dark";
                break;
            default:
                //classType = "";
                classText = "text-primary";
        }


        let classSize = "col-11 col-sm-11 col-md-9 col-lg-10 col-xl-10";
        switch(size){
            case 'sm':
                classSize = "col-8 col-sm-9 col-md-7 col-lg-7 col-xl-7";
                break;
            case 'md':
                classSize = "col-8 col-sm-10 col-md-8 col-lg-8 col-xl-8";
                break;
            case 'lg':
                classSize = "col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10";
                break;
            default:
                classSize = "col-8 col-sm-10 col-md-8 col-lg-8 col-xl-8";
                break;
        }

        let showButtons = false;
        if ((noButtons === undefined && (type === alertConstants.CONFIRM)) || noButtons === false ) {
            showButtons = true;
        }
        let iconObj = (icon && <Icon height="38" icon={icon} className=""/>);
        return (
            <div className={"bg-dialog justify-content-center align-items-center "} >
                <div className={"dialog-body d-flex align-items-center justify-content-center rounded shadow p-3 "+classSize+" "+(allScreen ? " allScreen" : "")}>
                    <div className="w-100">
                        <div className="d-flex justify-content-between">
                            <h5 className="m-2  d-flex align-items-center font-weight-bold float-left">{iconTitle && <InlineIcon height="32" icon={iconTitle} className="mr-2"/>} {title}</h5>
                            { type !== alertConstants.LOADING &&
                            <button onClick={this.closeDialog} className="btn py-0 px-3 text-muted btn-link"><Icon height="24" icon={x}/></button>}
                        </div>
                        <div className={" border-light "+(classText)}>
                            { (type !== alertConstants.LOADING && iconObj) && <div>{iconObj}<br/></div>}
                            { type === alertConstants.LOADING && <div><Icon height="32" className="spin" icon={loadingIcon}/><br/></div>}
                            { message && (<div className="text-dialog">{ message }</div>)}
                            { content && <div className="mt-3 mx-3">{content}</div> }
                        </div>
                        { 
                            showButtons && 
                            <div className="text-center pt-4">
                                { type !== alertConstants.LOADING && 
                                    <button onClick={this.evtPrimary} className={"btn btn-"+(btnPrimaryOutline ? "outline-" : "")+(!colorBtnPrimary ? "primary" : colorBtnPrimary)}>{!textBtnPrimary ? language.bundle.btnOK : textBtnPrimary}</button>}    
                                    
                                { (type === alertConstants.CONFIRM || textBtnSecondary) && 
                                    <button onClick={this.evtSecondary} className={"btn ml-3 btn-"+(btnSecondaryOutline ? "outline-" : "")+(!colorBtnSecondary ? "danger" : colorBtnSecondary)}>{!textBtnSecondary ? language.bundle.btnCancel : textBtnSecondary}</button> }            
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { alert, language } = state;
    return {
        alert,
        language
    };
};
export default connect(mapStateToProps)(Dialog);
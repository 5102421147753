import  { notificationConstants }  from '../_constants';

/*export default function notification(state = {}, action) {
  switch (action.type) {
    case notificationConstants.SUCCESS:
      return {
        type: 'notify-success',
        icon: action.icon,
        title: action.title,
        message: action.message
      };
    case notificationConstants.INFO:
      return {
        type: 'notify-info',
        icon: action.icon,
        title: action.title,
        message: action.message
      };
    case notificationConstants.WARNING:
      return {
        type: 'notify-warning',
        icon: action.icon,
        title: action.title,
        message: action.message
      };
    case notificationConstants.DANGER:
      return {
        type: 'notify-danger',
        icon: action.icon,
        title: action.title,
        message: action.message
      };
    case notificationConstants.CLEAR:
      return {};
    default:
      return state
  }
}*/


export default function notification(state = {}, action) {
  switch (action.type) {
    case notificationConstants.SUCCESS:
      return {
        type: notificationConstants.SUCCESS,
        params: action
      };
    case notificationConstants.ERROR:
      return {
        type: notificationConstants.ERROR,
        params: action
      };
    case notificationConstants.CONFIRM:
      return {
        type: notificationConstants.CONFIRM,
        params: action
      };
    case notificationConstants.WARNING:
      return {
        type: notificationConstants.WARNING,
        params: action
      };
    case notificationConstants.LOADING:
      return {
        type: notificationConstants.LOADING,
        params: action
      };
    case notificationConstants.INFO:
      return {
        type: notificationConstants.INFO,
        params: action
      };
    case notificationConstants.PRIMARY:
      return {
        type: notificationConstants.PRIMARY,
        params: action
      };
    case notificationConstants.CLEAR:
      return {};
    default:
      return state
  }
}
import { alertConstants } from '../_constants';

export const alertActions = {
    success,
    warning,
    error,
    confirm,
    info,
    primary,
    loading,
    dialog,
    clear
};

function success(params) {
    return { type: alertConstants.SUCCESS, ...params};
}

function confirm(params) {
    return { type: alertConstants.CONFIRM, ...params };
}

function dialog(params) {
    return { type: alertConstants.DIALOG, ...params };
}

function warning(params) {
    return { type: alertConstants.WARNING, ...params };
}

function loading(params) {
    return { type: alertConstants.LOADING, ...params };
}

function error(params) {
    return { type: alertConstants.ERROR, ...params };
}

function info(params) {
    return { type: alertConstants.INFO, ...params };
}

function primary(params) {
    return { type: alertConstants.PRIMARY, ...params };
}

function clear() {
    return { type: alertConstants.CLEAR };
}
import  { alertConstants }  from '../_constants/alert.constants';

export default function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: alertConstants.SUCCESS,
        params: action
      };
    case alertConstants.ERROR:
      return {
        type: alertConstants.ERROR,
        params: action
      };
    case alertConstants.CONFIRM:
      return {
        type: alertConstants.CONFIRM,
        params: action
      };
    case alertConstants.DIALOG:
      return {
        type: alertConstants.DIALOG,
        params: action
      };
    case alertConstants.WARNING:
      return {
        type: alertConstants.WARNING,
        params: action
      };
    case alertConstants.LOADING:
      return {
        type: alertConstants.LOADING,
        params: action
      };
    case alertConstants.INFO:
      return {
        type: alertConstants.INFO,
        params: action
      };
    case alertConstants.PRIMARY:
      return {
        type: alertConstants.PRIMARY,
        params: action
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return state
  }
}
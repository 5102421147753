import React from 'react';
import { connect } from "react-redux";
import { mask } from '../mask';
import Icon from '@iconify/react';

class TextField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {validation: null, inited: false};
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    label() {

        const { size, label, icon, language } = this.props;

        if (label === "" || label === undefined) {
            return false;
        }
        let classLabel = "";
        if (size === "lg") {
            classLabel += " form-label-lg";
        } else  if (size === "sm") {
            classLabel += " form-label-sm";
        }

        let objIcon;
        if(icon) {
            objIcon = <Icon icon={icon}/>
        }

        return (<label className={"font-weight-bold "+classLabel} htmlFor={this.props.id}>{objIcon} {this.props.label} {(!this.props.required && this.props.label) && <small className="text-muted">(opcional)</small>}</label>)
    }

    onChangeValue(e) {
        e.preventDefault();
        e.target.value = this.mask(e.target.value);


        if (this.props.onChange) {
            this.props.onChange(e);
        }

        if (this.props.pattern !== "" && this.props.pattern !== undefined) {
            if(this.props.pattern.test(e.target.value)){
                this.setState({validation: true});
            } else {
                this.setState({validation: false});
            }
        }
    }

    mask(value){
        if (typeof value === "undefined") {
            return value;
        }
        switch(this.props.mask){
            case 'number':
                return mask.number(value);
            case 'datehour':
                return mask.textDateHour(value);
            case 'numberCard':
                return mask.numberCard(value);
            case 'capitalize':
                return mask.capitalize(value);
            case 'monthYear':
                return mask.monthYear(value);
            case 'date':
                return mask.textDate(value);
            case 'phone':
                return mask.phone(value);
            case 'cnpj':  
                return mask.cnpj(value);
            case 'cpf':
                return mask.cpf(value);
            case 'money':
                return mask.money(value);
            case 'float':
                return mask.float(value);
            default:
                //this.setState({value: value});
                return value;
        }
    }

    input(value) {
        const {disabled, size, validation, name, type, id, placeholder, required, rows, onBlur, onFocus} = this.props;
        let classInput = "form-control";
        if (size === "lg") {
            classInput += " form-control-lg";
        } else  if (size === "sm") {
            classInput += " form-control-sm";
        }
        if (validation === true) {
            classInput += " is-valid";
        } else if (validation === false) {
            classInput += " is-invalid";
        }

       
        value = this.mask(value);
        
        if (type === "textarea") {
            return (<textarea disabled={disabled ? true : false} rows={rows} onFocus={onFocus} onBlur={onBlur} onChange={this.onChangeValue} value={value} name={name} type={type} className={classInput} id={id} placeholder={placeholder} required={required} />)
        }
        return (<input disabled={disabled ? true : false} onFocus={onFocus} onBlur={onBlur} onChange={this.onChangeValue} value={value} name={name} type={type} className={classInput} id={id} placeholder={placeholder} required={required} />)
    }

    render() {
        const {value, className, respInvalid, respValid} = this.props;
        let label = this.label();
        let input = this.input(value);

        return (
            <div className={"form-row " + (className !== undefined && className)}>
                { label !== false && label }
                {input}
                <div className="valid-feedback">{respValid}</div>
                <div className="invalid-feedback">{respInvalid}</div>
            </div>
        );
    }
}



function mapStateToProps(state) {
    const { alert, language } = state;
    return {
        alert,language
    };
};
export default connect(mapStateToProps)(TextField);
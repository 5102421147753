export const mask = {
    money: function(val){   
        if (val === undefined) {
            return "";
        }
        let x = "";
        if (typeof val === 'number') {
            x = val.toLocaleString('en-US', { minimumFractionDigits: 2 }).replace(/[^\d]+/g, '');
        } else {
            x = val.replace(/[^\d]+/g, '');
        }

        if (x === "") {
            return "";
        }
        if (x.length < 3) {
            x = parseFloat(x).toLocaleString('pt-BR', { minimumFractionDigits: 0 , style: 'currency', currency: 'BRL' });
        } else {
            x = parseFloat((x.substring(0, (x.length - 2)))+"."+(x.substring((x.length - 2),x.length))).toLocaleString('pt-BR', { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' });
        }
        return x;
        //return val.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
    },
    date: function(val){
        var d = new Date(val);
        if (val.length === 10) {
            if (val.split("-").length === 3) {
                let v = val.split("-");
                if (v[0].length === 4) {
                    d = new Date(v[0], parseInt(v[1])-1, v[2]);
                } else if (v[2].length === 4) {
                    d = new Date(v[2], parseInt(v[1])-1, v[0]);
                }
            }else if (val.split("/").length === 3) {
                let v = val.split("/");
                if (v[2].length === 4) {
                    d = new Date(v[2], parseInt(v[1])-1, v[0]);
                }
            }
        }
        //var d = new Date(dt1.valueOf() + (( dt1.getTimezoneOffset()/60 - 3) * 3600000));
        return (d.getDate() < 10 ? "0"+d.getDate() : d.getDate()) + "/" + ((d.getMonth() + 1) < 10 ? "0"+(d.getMonth() + 1) : (d.getMonth() + 1)) + "/" + d.getFullYear();
    },
    datehour: function(val){
        if (val) {
            return this.date(val) + " - " + this.hour(val);
        }
        return '--';
    },
    hour: function(val){
        var d = new Date(val);
        //var d = new Date(dt1.valueOf() + (( dt1.getTimezoneOffset()/60 - 3) * 3600000));
        return (d.getHours() < 10 ? "0"+d.getHours() : d.getHours()) + ":" + (d.getMinutes() < 10 ? "0"+d.getMinutes() : d.getMinutes());
    },
    acronymMonthName: function(n){
        switch(n){
            case 1:
                return "JAN";
            case 2:
                return "FEV";
            case 3:
                return "MAR";
            case 4:
                return "ABR";
            case 5:
                return "MAI";
            case 6:
                return "JUN";
            case 7:
                return "JUL";
            case 8:
                return "AGO";
            case 9:
                return "SET";
            case 10:
                return "OUT";
            case 11:
                return "NOV";
            case 12:
                return "DEZ";
            default:
                return "--";
        }
    },
    monthName: function(n){
        switch(n){
            case 1:
                return "Janeiro";
            case 2:
                return "Fevereiro";
            case 3:
                return "Março";
            case 4:
                return "Abril";
            case 5:
                return "Maio";
            case 6:
                return "Junho";
            case 7:
                return "Julho";
            case 8:
                return "Agosto";
            case 9:
                return "Setembro";
            case 10:
                return "Outubro";
            case 11:
                return "Novembro";
            case 12:
                return "Dezembro";
            default:
                return "--";
        }
    },
    cpf: (value) => {
        if (value === undefined || value === null) {
            return "";
        } 
        let x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + (!x[3] ? "" : '.'+x[3] + (!x[4] ? '' : '-' + x[4]));
    },
    phone (val) {
        if (val === undefined || val === null) {
            return "";
        } 
        var x = val.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    cep (val) {
        if (val === undefined || val === null) {
            return "";
        } 
        var x = val.replace(/\D/g, '').match(/(\d{0,5})(\d{0,3})/);
        return !x[2] ? x[1] : x[1] + '-' + x[2];
    },
    textDateHour: (value) => {
        if (value === undefined || value === null) {
            return "";
        } 
        let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})(\d{0,2})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '/' + x[2] + (!x[3] ? '' : '/' + x[3] + (!x[4] ? '' : ' ' + x[4] + (!x[5] ? '' : ':' + x[5])));
    },
    textDate: (value) => {
        if (value === undefined || value === null) {
            return "";
        } 
        let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
        return !x[2] ? x[1] : x[1] + '/' + x[2] + (x[3] ? "/" + x[3] : "");
        
    },
    monthYear: (value) => {
        if (value === undefined || value === null) {
            return "";
        } 
        let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '/' + x[2];
    },
    numberCard: (value) => {
        if (value === undefined || value === null) {
            return "";
        } 
        let x = value.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);
        return !x[2] ? x[1] : x[1] + ' ' + x[2] + (!x[3] ? '' : ' ' + x[3] + (!x[4] ? '' : ' ' + x[4]));
    },
    cnpj: (val) => {
        if (val === undefined || val === null) {
            return "";
        } 
        let x = val.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + (!x[3] ? '' : '.' + x[3] + (!x[4] ? '' : '/' + x[4] + (!x[5] ? '' : '-' + x[5])));
    },
    number: (val) => {
        if (val === undefined || val === null) {
            return "";
        } 
        return val.toString().replace(/[^\d]+/g, '')
    },
    float: (val) => {
        if (val === undefined || val === null) {
            return "";
        } 
        let x = this.money(val).replace(/[^\d]+/g, '');
        if (x === "") {
            return "";
        }
        if (x.length < 3) {
            x = parseFloat(x).toLocaleString('pt-BR', { minimumFractionDigits: 0 , style: 'currency', currency: 'BRL' });
        } else {
            x = parseFloat((x.substring(0, (x.length - 2)))+"."+(x.substring((x.length - 2),x.length))).toLocaleString('pt-BR', { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' });
        }
        return x.toString().replace("R$", '').replace(" ", '');
    },
    capitalize: (str) => {
        return str.replace(/(?:^|\s)\S/g, a => { return a.toUpperCase(); });
    },
    removeAccents(str) {
        let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
        let accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
        str = str.split('');
        str.forEach((letter, index) => {
          let i = accents.indexOf(letter);
          if (i !== -1) {
            str[index] = accentsOut[i];
          }
        })
        return str.join('');
    }
};